import "./App.css";
import { VictoryPie } from "victory-pie";
import { useState } from "react";
import React, { Component, Fragment } from "react";
import arrow from "./arrow-pointing-down-png.png";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { reactLocalStorage } from "reactjs-localstorage";

var cool = 1;

export default function App() {
  if (reactLocalStorage.getObject("playerData") === undefined) {
    reactLocalStorage.setObject("playerData", {});
  }

  if (reactLocalStorage.get("nameList") === undefined) {
    reactLocalStorage.set("nameList", "");
  }

  if (reactLocalStorage.getObject("hr") === undefined) {
    reactLocalStorage.setObject("hr", { x: "HR", y: 0.14 });
    reactLocalStorage.setObject("1b", { x: "1B", y: 0.14 });
    reactLocalStorage.setObject("2b", { x: "2B", y: 0.14 });
    reactLocalStorage.setObject("3b", { x: "3B", y: 0.14 });
    reactLocalStorage.setObject("so", { x: "SO", y: 0.14 });
    reactLocalStorage.setObject("oo", { x: "OO", y: 0.14 });
    reactLocalStorage.setObject("bb", { x: "BB", y: 0.14 });
  }

  const [startDegree, setStartDegree] = useState(0);
  const [hr, setHR] = useState(0.14);
  const [fb, setFB] = useState(0.14);
  const [sb, setSB] = useState(0.14);
  const [tb, setTB] = useState(0.14);
  const [bb, setBB] = useState(0.14);
  const [so, setSO] = useState(0.14);
  const [oo, setOO] = useState(0.14);

  const [startAngle, setStartAngle] = useState(0);
  const [finalStartAngle, setFinalStartAngle] = useState(0);

  const [total, setTotal] = useState(
      (hr + fb + sb + tb + bb + so + oo).toFixed(2)
  );

  const [playerCount, setPlayerCount] = useState(0);

  const [currHR, currSetHR] = useState(reactLocalStorage.getObject("hr"));
  const [currFB, currSetFB] = useState(reactLocalStorage.getObject("1b"));
  const [currSB, currSetSB] = useState(reactLocalStorage.getObject("2b"));
  const [currTB, currSetTB] = useState(reactLocalStorage.getObject("3b"));
  const [currBB, currSetBB] = useState(reactLocalStorage.getObject("bb"));
  const [currSO, currSetSO] = useState(reactLocalStorage.getObject("so"));
  const [currOO, currSetOO] = useState(reactLocalStorage.getObject("oo"));

  const [myData, setMyData] = useState([
    reactLocalStorage.getObject("hr"),
    reactLocalStorage.getObject("1b"),
    reactLocalStorage.getObject("2b"),
    reactLocalStorage.getObject("3b"),
    reactLocalStorage.getObject("so"),
    reactLocalStorage.getObject("oo"),
    reactLocalStorage.getObject("bb")
  ]);
  const [output, setOutput] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const urData = [
      { x: "HR", y: hr },
      { x: "1B", y: fb },
      { x: "2B", y: sb },
      { x: "3B", y: tb },
      { x: "SO", y: so },
      { x: "OO", y: oo },
      { x: "BB", y: bb }
    ];
    currSetHR(hr);
    currSetFB(fb);
    currSetSB(sb);
    currSetTB(tb);
    currSetSO(so);
    currSetOO(oo);
    currSetBB(bb);

    setMyData(urData);
    if (nameList.length === 1 && nameList[0] == "") nameList[0] = username;
    else nameList.push(username);
    setNameList(nameList);
    setFinalNameList(nameList);
    setCurrName(username);
    playerData[username] = urData;
    setPlayerData(playerData);
    setFinalPlayerData(playerData);
    setPlayerCount(nameList.length);
    console.log(playerCount);
    save();
    /*if(playerCount === 0){
      const drops = document.getElementById('drop');
      drops.style.display = 'none';
    }else{
      const drops = document.getElementById('drop');
      drops.style.display = 'inline';
    }*/
  };
  const [toggled, setToggle] = useState("zach");
  const colors = ["red", "orange", "yellow", "green", "blue", "purple", "pink"];

  const [username, setUsername] = useState("");
  const [nameList, setNameList] = useState(
      reactLocalStorage.get("nameList", ("")).split(",")
  );
  const [finalNameList, setFinalNameList] = useState(
      reactLocalStorage.get("nameList", ("")).split(",")
  );

  const [currName, setCurrName] = useState();

  const [playerData, setPlayerData] = useState(
      reactLocalStorage.getObject("playerData")
  );
  const [finalPlayerData, setFinalPlayerData] = useState(
      reactLocalStorage.getObject("playerData")
  );

  function del() {

  }

  function spinChart() {
    var spin = setInterval(() => {
      setStartAngle((startAngle) => startAngle + 20);
    }, 5);
    var time = 1500 + Math.random() * 360;
    function stopSpin() {
      clearInterval(spin);
    }
    setTimeout(stopSpin, time);
  }

  function updateHR(e) {
    setHR(Number(parseFloat(e.target.value)));
    var x = Number(e.target.value);
    setTotal(x + fb + sb + tb + bb + so + oo);
    console.log(total);
  }

  function update1B(e) {
    setFB(Number(parseFloat(e.target.value)));
    var x = Number(e.target.value);
    setTotal((hr + x + sb + tb + bb + so + oo).toFixed(2));
  }

  function update2B(e) {
    setSB(Number(parseFloat(e.target.value)));
    var x = Number(e.target.value);
    setTotal(hr + fb + x + tb + bb + so + oo).toFixed(2);
  }

  function update3B(e) {
    setTB(Number(parseFloat(e.target.value)));
    var x = Number(e.target.value);
    setTotal((hr + fb + sb + x + bb + so + oo).toFixed(2));
  }

  function updateSO(e) {
    setSO(Number(parseFloat(e.target.value)));
    var x = Number(e.target.value);
    setTotal((hr + fb + sb + tb + bb + x + oo).toFixed(2));
  }

  function updateOO(e) {
    setOO(Number(parseFloat(e.target.value)));
    var x = Number(e.target.value);
    setTotal((hr + fb + sb + tb + bb + so + x).toFixed(2));
  }

  function updateBB(e) {
    setBB(Number(parseFloat(e.target.value)));
    var x = Number(e.target.value);
    setTotal((hr + fb + sb + tb + x + so + oo).toFixed(2));
  }

  function save() {
    // player stats
    reactLocalStorage.setObject("playerData", finalPlayerData);
    // player roster
    reactLocalStorage.set("nameList", finalNameList);
    //current player
    reactLocalStorage.set("currName", currName);
    //current statistics
    reactLocalStorage.setObject("hr", myData[0]);
    reactLocalStorage.setObject("1b", myData[1]);
    reactLocalStorage.setObject("2b", myData[2]);
    reactLocalStorage.setObject("3b", myData[3]);
    reactLocalStorage.setObject("so", myData[4]);
    reactLocalStorage.setObject("oo", myData[5]);
    reactLocalStorage.setObject("bb", myData[6]);
  }

  function changeName(e) {
    setCurrName(e.value);
    //currSetBB(finalPlayerData[e.value])
    setMyData(finalPlayerData[e.value]);
    console.log(finalPlayerData[e.value]);
    currSetHR(finalPlayerData[e.value][0].y);
    currSetFB(finalPlayerData[e.value][1].y);
    currSetSB(finalPlayerData[e.value][2].y);
    currSetTB(finalPlayerData[e.value][3].y);
    currSetSO(finalPlayerData[e.value][4].y);
    currSetOO(finalPlayerData[e.value][5].y);
    currSetBB(finalPlayerData[e.value][6].y);

    setFB(finalPlayerData[e.value][1].y);
    setTB(finalPlayerData[e.value][2].y);
    setSB(finalPlayerData[e.value][3].y);
    setHR(finalPlayerData[e.value][0].y);
    setBB(finalPlayerData[e.value][6].y);
    setSO(finalPlayerData[e.value][4].y);
    setOO(finalPlayerData[e.value][5].y);

    setTotal(
        (
            finalPlayerData[e.value][1].y +
            finalPlayerData[e.value][2].y +
            finalPlayerData[e.value][3].y +
            finalPlayerData[e.value][0].y +
            finalPlayerData[e.value][6].y +
            finalPlayerData[e.value][4].y +
            finalPlayerData[e.value][5].y
        ).toFixed(2)
    );
    setUsername(e.value)

    save();
    //hi
    //whatup
    //just doin some react yessir
  }

  function stopSpin() {
    const box = document.getElementById("button1");
    box.style.display = "inline";
    const box1 = document.getElementById("output");
    box1.style.display = "inline";
    setToggle("App1");
  }

  function getRandomAngle() {
    return Math.random() * 365 + 1080;
  }

  function getOutcome(angle, data) {
    var runningSum = 0;

    const outcomes = [
      "Walk",
      "Other Out",
      "Strike Out",
      "Third Base",
      "Second Base",
      "First Base",
      "Home Run"
    ];

    const runSum = data.map(function (x) {
      let newX = x + runningSum;
      runningSum += x;
      return newX;
    });

    const normData = runSum.map(function (x) {
      return (x * 360) / Math.max(...runSum);
    });

    const subNormData = normData.map(function (x) {
      return x;
    });

    console.log(data);

    for (var i = 0; i < subNormData.length; i++) {
      if (angle % 360 < subNormData[i]) {
        return outcomes[i];
      }
    }
  }

  return (
      <Fragment>
        <form onSubmit={handleSubmit}>
          <label className="text2">1B: </label>
          <input
              type="number"
              required
              value={fb}
              onChange={(e) => update1B(e)}
              style={{ width: "6%" }}
          />

          <label class="text2">2B: </label>
          <input
              type="number"
              required
              value={sb}
              onChange={(e) => update2B(e)}
              style={{ width: "6%" }}
          />
          <label className="text2">3B: </label>
          <input
              type="number"
              required
              value={tb}
              onChange={(e) => update3B(e)}
              style={{ width: "6%" }}
          />
          <label class="text2">HR: </label>
          <input
              type="number"
              required
              value={hr}
              onChange={(e) => updateHR(e)}
              style={{ width: "6%" }}
          />
          <label class="text2">BB: </label>
          <input
              type="number"
              required
              value={bb}
              onChange={(e) => updateBB(e)}
              style={{ width: "6%" }}
          />
          <label class="text2">SO: </label>
          <input
              type="number"
              required
              value={so}
              onChange={(e) => updateSO(e)}
              style={{ width: "6%" }}
          />
          <label class="text2">OO: </label>
          <input
              type="number"
              required
              value={oo}
              onChange={(e) => updateOO(e)}
              style={{ width: "6%" }}
          />
          <label> Total </label>
          <input
              type="number"
              diabled="true"
              value={total}
              style={{ width: "6%" }}
          />
          <label class="text2">Player Name: </label>
          <input
              type="string"
              required
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              style={{ width: "8%" }}
          />
          <button> Add Player </button>
        </form>
        <form>
          <Dropdown
              options={finalNameList}
              value={currName}
              onChange={(e) => changeName(e)}
          />
        </form>

        <div id="div1" className="App">
          <h1 class="text1">Math Baseball Spinner</h1>
          <h2 class={"text3"}>{currName}</h2>
          <h2 id="output" class="text3">
            {" "}
            {output}{" "}
          </h2>
          <p> </p>
          <button onClick={spinChart} id="button1" class="btn btn-3">
            {" "}
            Spin{" "}
          </button>

          <p> </p>
          <img
              height="100px"
              width="100px"
              src={arrow}
              alt="Arrow"
              class="arrow"
          />
        </div>

        <div id="div2" className={toggled}>
          <VictoryPie
              data={myData}
              colorScale={colors}
              radius={160}
              class="spinner"
              startAngle={startAngle}
              endAngle={startAngle + 360}
          />
        </div>

        <p class="text2">
          {" "}
          Created by Caleb Palappillil '23 and Zach Berkowitz '23{" "}
        </p>
      </Fragment>
  );
}



